import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import logo from "../../assets/img/qntmicon.png";
import trademark from "../../assets/img/r.png";
import copy from "../../assets/img/copy.png";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div class="footer-basic">
      <footer>
        <div class="social">
          <a href="https://www.instagram.com/qntmcapitalhq/">
            <i class="icon ion-social-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/qntm-capital-ltd/?originalSubdomain=ng">
            <i class="icon ion-social-linkedin"></i>
          </a>
          <a href="https://twitter.com/qntmcapital_">
            <i class="icon ion-social-twitter"></i>
          </a>
          <a href="https://web.facebook.com/qntmcapital/">
            <i class="icon ion-social-facebook"></i>
          </a>
        </div>
        <ul class="list-inline">
          <li class="list-inline-item">
            <a href="#">Home</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Services</a>
          </li>

          <li class="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li class="list-inline-item">
            <a href="#">Privacy Policy</a>
          </li>
        </ul>
        <div class="copyright flexCenter flexColumn">
          <p>© Copyright</p>

          <p>QNTM Capital Limited © 2022. All Rights Reserved</p>
        </div>
      </footer>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const SmallerImg = styled.img`
  position: relative;
  right: -1.8rem;
  bottom: 1rem;
  width: 1rem;
`;
