import React from "react";
import styled from "styled-components";
// Assets
import lend from "../../assets/img/lend.png";
import apps from "../../assets/img/dapp.png";
import proof from "../../assets/img/proof.png";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <img src={lend} />;
      break;
    case "monitor":
      getIcon = <img src={proof} />;
      break;
    case "browser":
      getIcon = <img src={apps} />;
      break;

    default:
      getIcon = <img src={lend} />;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 semiBold">{title}</TitleStyle>
      <SubtitleStyle className="font18 ">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`;
const IconStyle = styled.div`
  img {
    width: 100%;
  }

  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: center;
  @media (max-width: 860px) {
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  margin: 0 auto;
  color: #1e78be;
`;
