import React from "react";
import styled from "styled-components";

import ClientSlider from "../Elements/ClientSlider";

const Partners = () => {
  return (
    <div style={{ margin: "3rem 0" }} id="partners">
      <HeaderInfo>
        <h1 className="font60 extraBold lightColor">Our Partners</h1>
      </HeaderInfo>
      <div className="lightBg" style={{ padding: "30px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
    </div>
  );
};

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

export default Partners;
