import styled from "styled-components";

import React, { useState, useRef, useEffect } from "react";
import FullButton from "../Buttons/FullButton";

export default function ProjectBox({ img, title, text, action }) {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Wrapper
      className={`question-section ${active}`}
      onClick={toggleAccordion}
      // onMouseEnter={toggleAccordion}
      // onMouseLeave={toggleAccordion}
    >
      <div className="question-align">
        <ImgBtn
          className="aniamte pointer"
          // onClick={action ? () => action() : null}
        >
          <img className="radius8" src={img} alt="project"></img>
        </ImgBtn>
      </div>

      <div
        ref={contentRef}
        className={active ? `answer answer-divider` : `answer`}
      >
        <h3 className="font20 extraBold">{title}</h3>
        <p className="font18 whiteColor">{text}</p>
        {/* <HeaderInfo>
          <h1 className="font40 extraBold lightColor greyColor">
            Coming Soon...
          </h1>
        </HeaderInfo> */}
        <div style={{ margin: "2rem 0" }}>
          <Buttn className="animate pointer radius8 extraBold font25">
            Coming Soon...
          </Buttn>
        </div>
      </div>
    </Wrapper>
  );
}

// export default function ProjectBox({ img, title, text, action }) {
//   return (
//     <Wrapper>
//       <ImgBtn
//         className="aniamte pointer"
//         onClick={action ? () => action() : null}
//       >
//         <img className="radius8" src={img} alt="project"></img>
//       </ImgBtn>
//       <h3 className="font20 extraBold">{title}</h3>
//       <p className="font15 whiteColor">{text}</p>
//     </Wrapper>
//   );
// }

const Wrapper = styled.div`
  width: 90%;
  max-width: 800px;
  margin-top: 30px;
  padding: 10px;
  img {
    width: 100%;
    height: auto;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const HeaderInfo = styled.div`
  text-align: center;
  padding: 2rem 0;
`;
const ImgBtn = styled.div`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;

  margin: 0px;
  :hover > img {
    opacity: 0.7;
  }
`;

const Buttn = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#1e78be")};
  background-color: ${(props) => (props.border ? "transparent" : "#fff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#1e78be")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#1e78be")};
    border: 1px solid #fff;
    color: ${(props) => (props.border ? "#1e78be" : "#fff")};
  }
`;
