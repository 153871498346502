import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";

export default function About() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Wrapper id="about">
      <div className="">
        <div>
          <HeaderInfo>
            <h1 className="font60 extraBold whiteColor">Who We Are</h1>
            <p
              className="font20 semiBold whiteColor"
              style={{ paddingTop: "2rem" }}
            >
              QNTM Capital Limited is an Emergent Blockchain Finance Company.
            </p>

            <div className="row flexCenter">
              <div
                style={
                  active
                    ? { display: "none" }
                    : {
                        display: "inline-block",
                        margin: "50px 0",
                        width: "200px",
                      }
                }
              >
                <FullButton title="Read More" action={toggleAccordion} />
              </div>
            </div>
            <div
              ref={contentRef}
              className={active ? `answer answer-divider` : `answer`}
            >
              <p className="font20 whiteColor" style={{ paddingTop: "2rem" }}>
                We are a hybrid Fintech startup entity that focuses on
                multi-tiered blockchain products and solutions with high social
                impact value. We significantly leverage the Blockchain
                technology to both catalyze and democratize the access to
                capital in the hinterlands of Africa.{" "}
                <div style={{ height: "20px" }} />
                We also design and Implement economic Intervention systems for
                Nations and more.
                <div style={{ height: "20px" }} /> Our inclusive and sustainable
                approach is fortified by our strategic global partnerships,
                purposefully centered on delivering absolute value to the
                end-user.
              </p>
              <div className="row flexCenter">
                <div
                  style={{
                    display: "inline-block",
                    margin: "50px 0",
                    width: "200px",
                  }}
                >
                  <FullButton title="Read Less" action={toggleAccordion} />
                </div>
              </div>
            </div>
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 3rem;
  margin: 2rem;
  padding-bottom: 3rem;
  text-align: center;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
