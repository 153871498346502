import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";
import About from "../components/Sections/About";
import Team from "../components/Sections/Team";
import Partners from "../components/Sections/Partners";

export default function Landing() {
  return (
    <>
      <div className="homer"></div>s
      <div
        className="flexColumn flexCenter"
        style={{
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <TopNavbar />
        <Header />

        <About />
        <Services />
        <Projects />
        {/* <Team /> */}

        <Contact />
        <Footer />
      </div>
    </>
  );
}
