import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/capital.png";
import HeaderImage1 from "../../assets/img/qntmlogo.png";
import trademark from "../../assets/img/r.png";
import BackgroundImage from "../../assets/img/home-bg.png";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

export default function Header() {
  return (
    <div style={{ width: "100vw" }}>
      <Wrapper id="home" className="container flexSpaceCenter">
        {/* <LeftSide className="flexCenter">
          <div>
            <h1 className="extraBold font60">
              We are an emergent Blockchain finance company.
            </h1>
            <HeaderP className="font18 semiBold">
              Leveraging the Blockchain technology to both catalyze and
              democratize the access to capital in the hinterlands of Africa.
            </HeaderP>
            <BtnWrapper>
              <FullButton title="Get Started" />
            </BtnWrapper>
          </div>
        </LeftSide> */}
        <RightSide>
          <ImageWrapper>
            <SmallerImg
              className=""
              src={trademark}
              alt="office"
              style={{ zIndex: 9 }}
            />
            <BigImg
              className="radius8"
              src={HeaderImage1}
              alt="office"
              style={{ zIndex: 9 }}
            />
            <SmallImg
              className="radius8"
              src={HeaderImage}
              alt="office"
              style={{ zIndex: 9 }}
            />

            {/* <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>
                  Friends, such as we desire, are dreams and fables. Friendship
                  demands the ability to do without it.
                </em>
              </p>
              <p
                className="font13 orangeColor textRight"
                style={{ marginTop: "10px" }}
              >
                Ralph Waldo Emerson
              </p>
            </div>
          </QuoteWrapper> */}
            {/* <DotsWrapper>
              <Dots />
            </DotsWrapper> */}
          </ImageWrapper>
          <TagLine className="lightColor font30 extraBold fade-in-image">
            An Emergent Blockchain Finance Company.
          </TagLine>
          {/* <GreyDiv className="blueBg"></GreyDiv> */}
        </RightSide>
        {/* <TagLine>an emergent Blockchain finance company.</TagLine> */}
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.section`
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 30px;
  width: 100%;
  height: 75vh;
  @media (max-width: 960px) {
    flex-direction: column;
    height: 65vh;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  color: white;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 10px 0 10px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 1;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const TagLine = styled.div`
  text-align: center;
  @media (max-width: 960px) {
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 80%;
    margin: auto;
    justify-content: center;
  }
`;
const BigImg = styled.img`
  width: 40rem;
  @media (max-width: 560px) {
    width: 100%;
    height: auto;
  }
`;
const SmallImg = styled.img`
  position: relative;
  left: 18rem;
  bottom: 3rem;
  width: 24rem;
  @media (max-width: 560px) {
    width: 65%;
    height: auto;
    left: 7.6rem;
    bottom: 1.6rem;
  }
`;

const SmallerImg = styled.img`
  position: relative;
  right: -33.8rem;
  bottom: -1.8rem;
  width: 3.5rem;
  @media (max-width: 560px) {
    width: 2.5rem;
    left: 62vw;
    bottom: -1.3rem;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
